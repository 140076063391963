@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap');/*Playfair Display*/
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps:wght@700&family=Playfair+Display:ital@1&display=swap');/*Oleo Script Swash Caps*/
@import url('https://fonts.googleapis.com/css2?family=Water+Brush&display=swap');/*Water Brush*/
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');/*Dancing Script*/
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); /*Bangers*/
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');/*Playball*/
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');/*Allura*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  height: fit-content;
  background-color: #282828;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

}
body::-webkit-scrollbar {
  display:none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
