.SectionOne {
  background-color: #282828;
  color: white;
  padding: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}



.SectionOne_Tiles_Block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.SectionOne_Tile {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  margin: 30px;
  padding: 20px;
  border: 1px solid #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
  border-radius: 10px;
  color: black;
}

.SectionOne_Tile_Image {
  width: 200px;
  height: 200px;
}

.SectionOne_Tile_Label {
  margin-top: 15px;
  padding: 0;
  font-size: x-large;
  font-weight: bold;
}

.SectionOne_Tile_Paragraph {
  /* width: 25%; */
  margin-top: 15px;
  padding: 0;
  font-size: large;
  font-weight: normal;
  word-wrap: break-word;
  word-break: normal;
  text-align: left;
}

.SectionOne_Span{
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color:#005a7c;
}

.SectionOne_Paragraph{
  /* textAlign: "left",
  textJustify: "left",
  padding: "0",
  margin: "0", */
  text-align: left;
  text-justify:inter-word;
  padding-left: 20px;
  padding-right: 20px;
}