
.CookieBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
  position: fixed;
  background-color: rgb(231, 215, 215);
  color: #005a7c;
  bottom: 0;
  padding: 10px;
  margin: 0 auto;
  width: 100%;
  /* height: 45px; */
  /* z-index: 1; */

}


.ReadMore {
  cursor: pointer;
  font-size:15px;
  font-weight: bold;
}

.AllowButton {
  cursor: pointer;
  width: 60px;
  padding: 10px;
  color: rgb(231, 215, 215);
  background-color: #005a7c;
  font-size:small;
  font-weight: bold;
  border-radius: 10px;

}

.Policy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.95); /* Black w/ opacity */
  color: white;
  font-size: xx-large;
/* pring to front of all other elements */
  z-index: 9999;


}

.Policy_Title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.close {
  cursor: pointer;
}
