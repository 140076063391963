.container {
  text-align: center;
  width: 100%;
  height: fit-content;
  left: 0;
  top: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #282828;
  color: white;
  /* transition: 10s ease-in-out; */
  transition: 0.9s;
}

.Skills {
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid white; */
}

.caticon:hover {
  cursor: pointer;
}

.catimage {
  margin-left: 35%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.showRoom {
  margin-top: 3%;
  /* margin: 0 auto; */
  padding: 0;
  /* width: fit-content; */
  text-align: center;
  flex-direction: row;
  justify-content:space-evenly;
  align-items: center;
  flex-wrap:wrap;

  /* border: 1px solid white; */
}

@keyframes fade_out_show {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade_in_show {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.Skillcont {
  width: fit-content;
  height: fit-content;
  /* margin-left: 150px; */
  /* margin-left: 0.5%;
  margin-right: 0.5%; */
  /* margin-top: 15px; */
  padding: 5px;
  /* border: 1px solid white; */
  /* display: block; */
  /* border: 1px solid white; */
}

.skillname {
  text-align: left;
  padding: 2px;
  font-size: x-large;
  text-shadow: 5px 5px 5px black;
  /* border: 1px solid white; */
}

.skillouter {
  /* width: 350px; */
  /* width: 450%; */
  border: 1px solid white;
}

.htmlperfill {
  padding: 4px;
  text-align: right;
  border: 1px solid white;
  background-color: #a3b6d3;
  color: #1f2c42;
}
