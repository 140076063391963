.Header {
  display: flex;
  background-color: #282828;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  height: 60px;
  overflow: hidden;
  /* border: 1px solid black; */
  top: 0;
  /* z-index: 10; */
  /* position:static; */
}

.header_navigation_link {
  color: white;
  text-decoration: none;
  font-size: xx-large;
  font-weight: bold;
  cursor: pointer;
}

.sticky {
  position: sticky;
  background-color: #282828;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  height: 60px;
  overflow: hidden;
  /* border: 1px solid black; */
  top: 0;
  /* z-index: 10; */
}

.fixed {
  position: fixed;
  background-color: #282828;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  height: 60px;
  overflow: hidden;
  /* border: 1px solid black; */
  top: 0;
  /* z-index: 10; */
}

.Header_Mobile {
  display: flex;
  background-color: #282828;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: fit-content;
  padding-top: 15px;
  padding-bottom: 20px;
  top: 0;
  /* z-index: 10; */
}
.Header_Mobile_Row {
  display: flex;
  background-color: #282828;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  /* height: 75px; */
  height: fit-content;
  overflow: hidden;
  top: 0;
  /* z-index: 10; */
}
.Header_Mobile_Nav {
  display: flex;
  background-color: #282828;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  top: 0;
  /* z-index: 10; */
  border: 2px solid #005a7c;
}
.Header_Mobile_Nav_Left {
  display: flex;
  background-color: #282828;
  flex-direction: column;
  justify-content:flex-start;
  align-items:flex-start;
  padding-left: 20px;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  top: 0;
  /* z-index: 10; */
}
.Header_Mobile_Nav_Right {
  display: flex;
  background-color: #282828;
  flex-direction: column;
  justify-content:space-between;
  align-items:flex-end;
  padding-right: 40px;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  top: 0;
  /* z-index: 10; */

}

.Header_Logo {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.Header_Text {
  color: white;
  text-decoration: none;
  font-size: xx-large;
  font-weight: bold;
  word-wrap: nowrap;

}
.Header_Nav_Text {
  color: white;
  text-decoration: none;
  font-size: x-large;
  font-weight: bold;
  cursor: pointer;
}
